<template>
  <article>
    <div class="page-container">
      <main style="display: contents">
        <section class="height-full mtb-auto">
          <div class="content-band flex align-center justify-center max-w800">
            <div
              class="
                container
                basis-50
                max-w500
                flex
                align-center
                justify-center
              "
            >
              <img
                src="../assets/404-image.png"
                style="width: 354px;"
                class="error-image"
                alt="404 Image"
              />
            </div>
            <div
              class="
                container
                flex
                justify-center
                align-center
                basis-50
                error-message
              "
            >
              <div class="container max-w300">
                <h1 class="font-iop-blue error-number light">404</h1>
                <div class="error-pagenotfound">Page not found</div>
                <p class="max-w300">
                  The link you followed may be outdated or the page may have
                  moved.
                </p>
                <button
                  class="
                    primary
                    iop-drk-blue
                    mt-30
                    rounded-full
                    hover-white hover-shadow hover-font-blue
                  "
                >
                  <div class="btn-txt font-white">Return Home</div>
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        class="container flex justify-center align-center gray-200"
        style="height: 120px"
      >
        <div>
          <img
            src="../assets/iop-logo-gray.png"
            alt="Innovate Ohio Platform Logo"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "NotFoundErrorComponent",
  props: ["type", "subtype", "text", "icon"],
};
</script>

<style scoped>
.error-number {
  font-size: 86px;
}
.error-pagenotfound {
  color: #557aed;
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .error-message {
    text-align: -webkit-center;
  }
  .error-image {
    width: 324px !important;
    height: 199px !important;
  }
}
</style>

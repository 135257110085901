<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Error 404</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              The 404 Not Found Error is the HTTP status code that indicates the
              server can’t find the requested resource.
            </p>
            <div class="gray-250 p-30 w800 mtb-30">
              <div class="font-red semibold">Suggested messaging:</div>
              <p>
                404 / The link you followed may be outdated or the page may have
                moved.
              </p>
              <p>{{ buttonTextExample }}</p>
            </div>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h3>Design Vew</h3>
            <CodeView block="pages" type="404-error-page">
              <NotFoundErrorComponent type="primary" text="NotFoundError" />
            </CodeView>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TempLeftNav from "../TempLeftNav";
import NotFoundErrorComponent from "../NotFoundErrorComponent";
import CodeView from "../CodeView";
export default {
  name: "NotFoundError",
  data: function() {
    return {
      buttonTextExample: "<Return to [site name]>.",
      primary: {
        main: {
          name: "Error 404",
          codeHTML: `<!DOCTYPE html>
<html>
    <head lang="en">
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="preconnect" href="https://fonts.gstatic.com">
        <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet">
        <link rel="stylesheet" href="../../assets/css/ds-core.css">
        <title>404 Error Page</title>
    </head>
    
    <body>
        <div class="page-container">
            <main style="display: contents;">
                <section class="height-full mtb-auto">
                    <div class="content-band flex align-center justify-center max-w800">
                        <div class="container basis-50 max-w500 flex align-center justify-center">
                            <img src= "../assets/404-image.png" style="width: 384px; height: 239px;" class="error-image" alt="404 Image">
                        </div>
                        <div class="container flex justify-center align-center basis-50 error-message">
                            <div class="container max-w300">
                                <h1 class="font-iop-blue error-number light">404</h1>
                                <div class="error-pagenotfound">Page not found</div>
                                <p class="max-w300">
                                    The link you followed may be outdated or the page may have moved.
                                </p>
                                <button class="primary iop-drk-blue mt-30 rounded-full hover-white hover-shadow hover-font-blue">
                                    <div class="btn-txt font-white">Return Home</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <div class="container flex justify-center align-center gray-200" style="height: 120px;">
                <div>
                    <img src="../assets/iop-logo-gray.png" alt="Innovate Ohio Platform Logo"/>
                </div>
            </div>
        </div>
    </body>
</html>`,
          codeCSS: `
			.error-number {
                font-size: 86px;
            }
            .error-pagenotfound {
                color: #557aed;
                font-size: 30px;
            }
            @media only screen and (max-width: 600px) {
                .error-message {
                    text-align: -webkit-center;
                }
                .error-image{
                    width: 324px !important;
                    height: 199px !important;
                }
            }`,
          codeJS: "No JS Needed",
          preview: "This is the preview",
        },
        mainWithIcon: {},
        disabled: {},
      },
    };
  },
  components: {
    TempLeftNav,
    NotFoundErrorComponent,
    CodeView,
  },
};
</script>
